import React from "react";

export default function Contact(){

    return(
        <div className="sec-box contact section-padding bord-thin-top" data-scroll-index="5">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="sec-head md-mb80 wow fadeIn custom_mobile_margin">
                            <h6 className="sub-title mb-15 opacity-7">Get In Touch</h6>
                            <h2 className="fz-50">Let's make your brand brilliant!</h2>
                            <p className="fz-15 mt-10">If you would like to work with me or want to hire me, I would love to hear from you!</p>
                            <div className=" mt-30 ">
                                <a href="tel:+1 548 255 5786<" className="main-color"><strong>Call:</strong> +1 548 255 5786</a>
                            </div>
                            <div className=" mt-30 ">
                                <a href="tel:+1 548 255 5786<" className="main-color"><strong>Email:</strong> info@mohammadsaifhossain.com</a>
                            </div>
                            <div className="col-md-4 valign">
                                <div className="social text-center full-width">
                                </div>
                            </div>
                            <ul className="rest social-text d-flex mt-30">
                                <li className="mr-30">
                                   <a href="https://www.facebook.com/mohammedsaif.hossain/" target='_blank'><i className="fa fa-facebook"></i></a>
                                </li>
                                <li className="mr-30">
                                   <a href="https://github.com/saifhossin1441" target='_blank'><i className="fa fa-github"></i></a>
                                </li>
                                <li className="mr-30">
                                   <a href="https://www.linkedin.com/in/mohammad-saif-hossain-99baa5125/" target='_blank'><i className="fa fa-linkedin"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <p>Are you seeking a seasoned professional with a proven track record in web design and development?</p><p> Look no further. With over six years of hands-on experience, I have honed my skills in various facets of web development, from intricate website architecture to seamless front-end and back-end solutions. My expertise extends to domain management, SSL certificates, and hosting solutions, ensuring a comprehensive approach to crafting fluid and secure online experiences for clients.</p>
                        <br/>
                        <p>My proficiency extends to frontend technologies like HTML5, CSS3, JavaScript, and React.js, as well as backend technologies including Python, Laravel, and PHP. I am well-versed in design tools such as Canva and Figma, and possess a strong understanding of CMS platforms like WordPress and Wix. My problem-solving skills, analytical thinking, and effective communication have been crucial in delivering outstanding results.</p>
                        <div className="full-width wow fadeIn">
                            {/* <form id="contact-form" method="post" >

                                <div className="messages"></div>

                                <div className="controls row">

                                    <div className="col-lg-6">
                                        <div className="form-group mb-30">
                                            <input id="form_name" type="text" name="name" placeholder="Name" required="required" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-30">
                                            <input id="form_email" type="email" name="email" placeholder="Email" required="required" />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group mb-30">
                                            <input id="form_subject" type="text" name="subject" placeholder="Subject" />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea id="form_message" name="message" placeholder="Message" rows="4" required="required"></textarea>
                                        </div>
                                        <div className="mt-30">
                                            <button type="submit">
                                                <span className="text">Send A Message</span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form> */}
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>

    )
}