import React from "react";
import './css/style.css'; // Import the CSS file
import portfolio1 from "./images/portfolio1.png";
import portfolio2 from "./images/portfolio2.png";
import portfolio3 from "./images/portfolio3.png";
import portfolio4 from "./images/portfolio4.png";
import portfolio_pdf from "./pdf/portfolio.pdf";
export default function Portfolio(){
    
    return(
<div className="sec-box portfolio section-padding main-bg " data-scroll-index="3">
                <div className="sec-head mb-30">
                    <div className="row">
                        <div className="col-lg-8">
                            <h6 className="sub-title opacity-7 custom_mobile_margin mb-15">My Portfolio</h6>
                            <h3>Innovative Design, Seamless Development: Your Web Journey Starts Here </h3>
                            {/* <p  >I have successfully crafted over 50 websites, showcasing a selection of my freelance projects. The remaining websites were conceived and developed during my tenure with a company, where I played a pivotal role in their design and development.</p> */}
                        </div>
                        <div className="col-lg-6 valign">
                            <div className="go-more full-width d-flex justify-content-end">
                                <a href="works.html" className="d-flex">
                                    {/* <span>View All Works <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 34.2 32.3" xml:space="preserve" style="stroke-width: 2;">
                                        <line x1="0" y1="16" x2="33" y2="16"></line>
                                        <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
                                        <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
                                    </svg></span> */}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gallery">
                    <div className="row">
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".2s">
                                <div className="img-width"> 
                                    <a href="https://textcher.ca/">
                                        <img className="img-width" src={portfolio1} alt="" />
                                    </a>
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">TextCher</span>
                                        <h6 className="line-height-1"><a href="https://textcher.ca/">IT Agency Website</a>
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                            <a href="https://textcher.ca/">
                                                {/* <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 34.2 32.3" xml:space="preserve" style="stroke-width: 2;">
                                                    <line x1="0" y1="16" x2="33" y2="16"></line>
                                                    <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
                                                    <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
                                                </svg> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".8s">
                                <div className="img-width">
                                    <a href="/">
                                        <img className="img-width" src={portfolio4} alt="" />
                                    </a>
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">UI UX Design</span>
                                        <h6 className="line-height-1"><a href="/">Product Design</a>
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                            <a href="/">
                                                {/* <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 34.2 32.3" xml:space="preserve" style="stroke-width: 2;">
                                                    <line x1="0" y1="16" x2="33" y2="16"></line>
                                                    <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
                                                    <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
                                                </svg> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".4s">
                                <div className="img-width">
                                    <a href="https://dealzup.ca/">
                                        <img className="img-width" src={portfolio2} alt="" />
                                    </a>
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">Dealzup</span>
                                        <h6 className="line-height-1"><a href="https://dealzup.ca/">Landing Page</a>
                                        </h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                            <a href="https://dealzup.ca/">
                                                {/* <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 34.2 32.3" xml:space="preserve" style="stroke-width: 2;">
                                                    <line x1="0" y1="16" x2="33" y2="16"></line>
                                                    <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
                                                    <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
                                                </svg> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 items">
                            <div className="item mt-50 wow fadeInUp" data-wow-delay=".6s">
                                <div className="img-width">
                                    <a href="https://manikyajewellery.com/">
                                        <img className="img-width" src={portfolio3} alt="" />
                                    </a>
                                </div>
                                <div className="cont mt-30 d-flex align-items-center">
                                    <div>
                                        <span className="tag">Manikya Jewellery</span>
                                        <h6 className="line-height-1"><a href="https://manikyajewellery.com/">eCommerce website</a></h6>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="arrow">
                                            <a href="https://manikyajewellery.com/">
                                                {/* <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 34.2 32.3" xml:space="preserve" style="stroke-width: 2;">
                                                    <line x1="0" y1="16" x2="33" y2="16"></line>
                                                    <line x1="17.3" y1="0.7" x2="33.2" y2="16.5"></line>
                                                    <line x1="17.3" y1="31.6" x2="33.5" y2="15.4"></line>
                                                </svg> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div>
               
                <div className="butn-presv mt-4 custom_portfolio">
                  <a href={portfolio_pdf}  target="_blank"  rel="noreferrer" className="butn butn-md butn-bord radius-5 skew">
                    <span>Portfolio</span>
                  </a>
                </div>
               </div>
               
            </div>
    )

}