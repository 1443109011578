import React from 'react'
import logo from "./images/logo.png";

export default function Navbar(){
      
      return(
        <>
        {/* // <!-- ==================== End Loading ==================== --> */}
 
        {/* // <!-- ==================== Start progress-scroll-button ==================== --> */}
    
        <div className="progress-wrap cursor-pointer">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewbox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
            </svg>
        </div>
    
        {/* // <!-- ==================== End progress-scroll-button ==================== --> */}
    
    
    
        {/* // <!-- ==================== Start lines ==================== --> */}
    
        <div className="lines">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    
        {/* // <!-- ==================== End lines ==================== -->
    
    
        // <!-- ==================== Start contact button ==================== --> */}
    
        <div className="contact-fixed-butn">
            <div className="butn-presv">
                <a href="mailto:info@mohammadsaifhossain.com" className="butn butn-sm bg-white skew" data-scroll-nav="5">
                    <span className="text-dark">Contact Me</span>
                </a>
            </div>
        </div>
    
        {/* // <!-- ==================== End contact button ==================== --> */}
    
        <div className="nav-top pt-30 pb-30 hide-mobile" >
        <div className="container">
            <div className="row">
                <div className="col-md-4 valign">
                <img src={logo}  className="nav-logo " id="myLogo" alt="" /> 
                  </div>
                <div className="col-md-4 valign">
                    <div className="social text-center full-width">
                        <a href="https://www.facebook.com/mohammedsaif.hossain/" target='_blank'><i className="fa fa-facebook color-custom"></i></a>
                        <a href="https://github.com/saifhossin1441" target='_blank'><i className="fa fa-github color-custom"></i></a>
                        <a href="https://www.linkedin.com/in/mohammad-saif-hossain-99baa5125/" target='_blank'><i className="fa fa-linkedin color-custom"></i></a>
                    </div>
                </div>
                <div className="col-md-4 valign">
                    <div className="full-width info">
                        <div className="d-flex justify-content-end">
                            <a href="mailto:info@mohammadsaifhossain.com">
                                <span className="sub-title fz-12 color-custom">info@mohammadsaifhossain.com</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    </>
      )
}