import React from 'react';
import Typewriter from './Typewriter';
import profile from "./images/profile_pic.jpeg";
import icon1 from "./images/icon1.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/icon3.png";
import icon4 from "./images/icon4.png";
import icon5 from "./images/icon5.png";
import icon6 from "./images/icon6.png";
import "./css/typewriter.css"
import cv from "./pdf/Mohammad_Saif_Hossain_cv_template.pdf";


export default function Introduction() {
  return (
    <section className="intro-profile md-mb50 myInlineStyle " >
      <div className="row rest">
        <div className="col-lg-4 box-img main-bg">
          <div className="cont valign">
            <div className="full-width">
              <div className="img">
                <img src={profile} alt="Mohammad Saif Hossain" />
                <span className="icon">
                  <img src={icon1} alt="PHP" />
                </span>
                <span className="icon">
                  <img src={icon2}  alt="Wordpress" />
                </span>
                <span className="icon">
                  <img src={icon3}  alt="Figma" />
                </span>
                <span className="icon">
                  <img src={icon4}  alt="Javascript" />
                </span>
                <span className="icon">
                  <img src={icon5}  alt="React" />
                </span>
                <span className="icon">
                  <img src={icon6}  alt="Python" />
                </span>
              </div>
              <div className="info text-center mt-30">
                <p className="fz-13 text-u">Available For Freelance</p>
              </div>
              <div className="social text-center mt-20">
                <a href="https://www.facebook.com/mohammedsaif.hossain/" target='_blank'>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="https://github.com/saifhossin1441" target='_blank'>
                  <i className="fa fa-github" aria-hidden="true"></i>
                </a>
                <a href="https://www.linkedin.com/in/mohammad-saif-hossain-99baa5125/" target='_blank'>
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 content main-bg">
          <h1>
            Hello, I’m{" "}
            <span className="main_color">
              Mohammad Saif Hossain.
              <i></i>
            </span>
            <span className="bord">Full Stack Web Developer <i></i></span>
          </h1>

          <div className="stauts mt-20 mb-20">
            <div className="d-flex align-items-center">
              <div className="mr-40">
                <div className="d-flex align-items-center">
                  <h2>5</h2>
                  <p>
                    Years of Experience <br />
                    <br />
                    In IT Field{" "}
                  </p>
                </div>
              </div>

              <div>
                <div className="butn-presv">
                  <a href={cv}  target="_blank"  rel="noreferrer" className="butn butn-md butn-bord radius-5 skew">
                    <span>Download C.V</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
         <Typewriter   />
         
        </div>
      </div>
    </section>
  )
  }
