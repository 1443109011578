import React from 'react';
import Introduction from './Introduction';
import Services from './Services';
import Skills from './Skills';
import Portfolio from './Portfolio';
import Contact from './Contact';

export default function Home(){
    return(
        <>
        <Introduction />
        <Services />
        <Skills />
        <Portfolio />
        <Contact />
        </>
    )
}